export const BASE_VERSION = 'api/v1';

export const API_ENDPOINT = {
  public: {
    generateInvoice: `${BASE_VERSION}/generate-invoice`,
  },
  auth: {
    authLogin: `${BASE_VERSION}/login`,
    authRegister: `${BASE_VERSION}/register`,
    authForgotPassword: `${BASE_VERSION}/forgot-password`,
    authResetPassword: `${BASE_VERSION}/reset-password`,
  },
  sellers: {
    auth: {
      logout: `${BASE_VERSION}/sellers/logout`,
    },
    isLogged: `${BASE_VERSION}/sellers/is-logged`,
    currentSeller: `${BASE_VERSION}/sellers/current-user`,
    connectMl: `${BASE_VERSION}/sellers/ml/link-account`,
    mercadoLibre: {
      getProducts: `${BASE_VERSION}/sellers/ml/get-products`,
      getCategories: `${BASE_VERSION}/sellers/ml/categories`,
    },
    invoicer: {
      generateCreditNote: `${BASE_VERSION}/sellers/invoicer/generate-credit-note`,
    },
    data: {
      configuration: {
        invoices: {
        },
        taxInformation: {
          general: `${BASE_VERSION}/sellers/data/tax-information`,
          uploadCsd: `${BASE_VERSION}/sellers/data/tax-information/upload-csd`,
        }
      },
      invoices: {
        getInvoices: `${BASE_VERSION}/sellers/data/invoices`,
        getCreditNotes: `${BASE_VERSION}/sellers/data/credit-notes`,
        downloadPdf: `${BASE_VERSION}/sellers/data/invoices/get-pdf/`,
        downloadXml: `${BASE_VERSION}/sellers/data/invoices/get-xml/`,
        generalConfiguration: `${BASE_VERSION}/sellers/data/invoices/configuration/general`,
        getAvailableStamps: `${BASE_VERSION}/sellers/data/invoices/get-available-stamps`,
      },
      mercadoLibre: {
        myProducts: `${BASE_VERSION}/sellers/data/my-products-ml`,
        product: `${BASE_VERSION}/sellers/data/product-ml`,
        getOrder: `${BASE_VERSION}/sellers/data/order`,
      }
    },
  },
  invoicer: {
    infoOrder: `${BASE_VERSION}/info-order`,
    facturama: {
      uploadCsd: `${BASE_VERSION}/invoicer/csd/upload-csd`,
      deleteCsd: `${BASE_VERSION}/invoicer/csd/delete-csd`,
      generateCreditNote: `${BASE_VERSION}/invoicer/credit-note`,
      catalogs: {
        products: `${BASE_VERSION}/invoicer/catalog/products-services`,
        unitCodes: `${BASE_VERSION}/invoicer/catalog/units`,
        fiscalRegimens: `${BASE_VERSION}/invoicer/catalog/fiscal-regimens`,
        paymentsForms: `${BASE_VERSION}/invoicer/catalog/payment-forms`,
        paymentsMethods: `${BASE_VERSION}/invoicer/catalog/payment-methods`,
      }
    }
  },
  catalogs: {
    facturama: {
      products: `${BASE_VERSION}/catalogs/products-services`,
      unitCodes: `${BASE_VERSION}/catalogs/units`,
      fiscalRegimens: `${BASE_VERSION}/catalogs/fiscal-regimens`,
      paymentsForms: `${BASE_VERSION}/catalogs/payments-forms`,
      paymentsMethods: `${BASE_VERSION}/catalogs/payment-methods`,
      cfdiUses: `${BASE_VERSION}/catalogs/cfdi-uses`,
    }
  },
  store: {
    stripe: {
      buyStamps: `${BASE_VERSION}/store/buy-stamps`,
    }
  }
}