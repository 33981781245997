import ButtonComponent from "./Buttons/Button";
import CustomButtonComponent from "./Buttons/CustomButton";
import InformationCardComponent from "./Cards/InformationCard";
import FormComponent from "./Forms/Form";
import InputComponent from "./Inputs/Input";
import InputNumberComponent from "./Inputs/Number";
import CheckboxComponent from "./Checkbox/Checkbox";
import IconComponent from "./Icon/Icon";
import DraggerComponent from "./Inputs/Dragger";
import ModalComponent from "./Modals/Modal";
import AutocompleteComponent from "./Inputs/Autocomplete";

export const InformationCard = InformationCardComponent;
export const Input = InputComponent;
export const Inputs = {
  Input: InputComponent,
  InputNumber: InputNumberComponent,
  Autocomplete: AutocompleteComponent
}
export const Dragger = DraggerComponent;
export const Form = FormComponent;
export const Button = ButtonComponent;
export const CustomButton = CustomButtonComponent;
export const Checkbox = CheckboxComponent;
export const Icon = IconComponent;
export const Modal = ModalComponent;